/* eslint-disable func-names */
import {
  FILTER_DATA_FETCH_SUCCESS,
  FILTER_DATA_FETCH_FAILURE,
  SAVE_APPLIED_FILTERS,
  UPDATE_IS_FILTER_OPEN,
  UPDATE_LAST_UPDATED_DATE,
  IS_MEMBER_FILTER_SELECTED,
  SAVE_PA_TOPICS_SUBTOPICS,
  UPDATE_FILTER_STATE,
  UPDATE_FILTER_STATE_BC,
  SAVE_APPLIED_FILTERS_BC,
  UPDATE_SELECTED_DATE,
  SAVE_APPLIED_COLLECTION,
  SAVE_SELECTED_DATA_FOR_FILTERS,
} from './actionTypes';

import { batch } from 'react-redux';
import { beginAPICall, endAPICall } from './apiCallActions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import {
  fetchDeleteWithId,
  fetchGet,
  fetchPost,
  fetchPut,
} from 'utils/KtCommonServices';
import CONFIG from 'config/configProps';
import { isEmpty } from 'utils/common';
import { seperatePaTopicSubTopicData } from 'utils/dd';
import { isEmptyOrNull, isFetchRequired } from 'utils';

const MESSAGES = {
  GROUP: 'FILTERS',
  ERROR: 'Network error, please try again later',
};

/**
 * Action Creator: Seed Data fetch Success
 * @param {*} data data received from API
 * @param {*} apiIdentifier API identifier
 */
function seedDataFetchSuccess(data, apiIdentifier) {
  return {
    type: FILTER_DATA_FETCH_SUCCESS,
    id: apiIdentifier,
    results: data,
  };
}
/**
 * Action Creator: Seed Data Fetch Failure
 * @param {*} error error received from API
 * @param {*} apiIdentifier  API identifier
 */
function seedDataFetchFailure(error, apiIdentifier) {
  return {
    type: FILTER_DATA_FETCH_FAILURE,
    id: apiIdentifier,
    error,
  };
}

function fetchMemberList(fetchMemberList, postData, updateImpersonatorList) {
  return async function (dispatch) {
    fetchMemberList &&
      dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST));
    fetchMemberList &&
      fetchPostList(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST,
        false,
        updateImpersonatorList
      );
  };
}

function clearFiltersData(postData) {
  return fetchSeedDataFilters(true, true, true, true, true, postData, true);
}

function clearDefaultFilters() {
  return async function (dispatch) {
    dispatch(
      seedDataFetchSuccess({}, API_CALL_IDENTIFIERS.FETCH_DEFAULT_USER_FILTERS)
    );
  };
}

/**
 * THUNK: fetch seed data for ALL Filters for Filter Section
 */
function fetchSeedDataFilters(
  fetchPaList,
  fetchGeoLocationList,
  fetchJobTitleList,
  fetchLocationList,
  fetchUserDefaultFilters,
  postData,
  fetchMemberList
) {
  return async function (dispatch) {
    batch(() => {
      dispatch(saveAppliedFiltersInRedux(postData));
      // batch all Seed Data Calls dispatch as ONE

      fetchPaList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_PRACTICE_AREA_LIST));
      fetchGeoLocationList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_GEO_LOCATION_LIST));
      fetchJobTitleList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_JOB_TITLE_LIST));
      fetchLocationList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_LOCATION_LIST));
      fetchMemberList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST));
      fetchUserDefaultFilters &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_DEFAULT_USER_FILTERS));
    });
    fetchPaList &&
      fetchPostList(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_PRACTICE_AREA_LIST
      );
    fetchGeoLocationList &&
      fetchPostList(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_GEO_LOCATION_LIST
      );
    fetchJobTitleList &&
      fetchPostList(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_JOB_TITLE_LIST
      );
    fetchLocationList &&
      fetchPostList(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_LOCATION_LIST
      );
    fetchMemberList &&
      fetchPostList(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST
      );
    fetchUserDefaultFilters &&
      fetchGetData(
        dispatch,
        API_CALL_IDENTIFIERS.FETCH_DEFAULT_USER_FILTERS,
        postData
      );
  };
}

function fetchFiltersForBusinessComparison(
  fetchPaList,
  fetchGeoLocationList,
  fetchJobTitleList,
  fetchLocationList,
  fetchMemberList,
  postData,
  employeeIdHRO
) {
  return async function (dispatch) {
    batch(() => {
      dispatch(saveAppliedFiltersBc(postData));
      // batch all Seed Data Calls dispatch as ONE
      fetchPaList &&
        dispatch(
          beginAPICall(API_CALL_IDENTIFIERS.FETCH_PRACTICE_AREA_LIST_BC)
        );
      fetchGeoLocationList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_GEO_LOCATION_LIST_BC));
      fetchJobTitleList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_JOB_TITLE_LIST_BC));
      fetchLocationList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_LOCATION_LIST_BC));
      fetchMemberList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST_BC));
    });
    fetchPaList &&
      fetchPostList(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_PRACTICE_AREA_LIST_BC
      );
    fetchGeoLocationList &&
      fetchPostList(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_GEO_LOCATION_LIST_BC
      );
    fetchJobTitleList &&
      fetchPostList(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_JOB_TITLE_LIST_BC
      );
    fetchLocationList &&
      fetchPostList(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_LOCATION_LIST_BC
      );
    fetchMemberList &&
      fetchPostList(
        dispatch,
        { ...postData, employeeIdHRO },
        API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST_BC
      );
  };
}

/**
 * Method to check if 'error' is being set from ServiceLayer. If yes, dispatch API-FAIL action
 * @param {*} param0 error
 * @param {*} apiIdentifier API Identifier
 * @param {*} dispatch dispatch Ref
 */
function isErroneousResponse({ error }) {
  if (error) {
    throw new Error(`${MESSAGES.ERROR}`);
  }
  return false;
}

async function fetchPostList(
  dispatch,
  data,
  apiIdentifier,
  isEditModeOn,
  updateImpersonatorList
) {
  let endpoint;
  let baseUrl = CONFIG.KT_FILTERS_API_URL;
  let formatData = false;
  let seperateApiData = false;
  let getCollectionData = false;
  const FILTERS = CONFIG.PROTOTYPE_FILTERS;
  let dataToPost = { ...data };
  switch (apiIdentifier) {
    case API_CALL_IDENTIFIERS.FETCH_JOB_TITLE_LIST:
    case API_CALL_IDENTIFIERS.FETCH_JOB_TITLE_LIST_BC:
      delete dataToPost?.[FILTERS.JOB_TITLE.field];
      endpoint = CONFIG.PROTOTYPE_API_URLS.JOB_TITLE;
      break;
    case API_CALL_IDENTIFIERS.FETCH_LOCATION_LIST:
    case API_CALL_IDENTIFIERS.FETCH_LOCATION_LIST_BC:
      delete dataToPost?.[FILTERS.LOCATION.field];
      endpoint = CONFIG.PROTOTYPE_API_URLS.LOCATION;
      formatData = true;
      break;
    case API_CALL_IDENTIFIERS.FETCH_PRACTICE_AREA_LIST:
      endpoint = CONFIG.PROTOTYPE_API_URLS.PRACTICE_AREA;
      seperateApiData = true;
      break;
    case API_CALL_IDENTIFIERS.FETCH_PRACTICE_AREA_LIST_BC:
      endpoint = CONFIG.PROTOTYPE_API_URLS.PRACTICE_AREA;
      break;
    case API_CALL_IDENTIFIERS.FETCH_GEO_LOCATION_LIST:
    case API_CALL_IDENTIFIERS.FETCH_GEO_LOCATION_LIST_BC:
      endpoint = CONFIG.PROTOTYPE_API_URLS.GEO_LOCATION;
      break;
    case API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST:
    case API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST_BC:
      delete dataToPost?.[FILTERS.NAME.field];
      endpoint = CONFIG.PROTOTYPE_API_URLS.TEAM_MEMBER;
      break;
    case API_CALL_IDENTIFIERS.SAVE_COLLECTION_DATA:
      endpoint = CONFIG.PROTOTYPE_API_URLS.SAVE_COLLECTION;
      baseUrl = CONFIG.KT_USER_BASE_URL;
      getCollectionData = true;
      break;
    default:
      endpoint = '';
  }
  let methodName = isEditModeOn ? fetchPut : fetchPost;
  try {
    if (!getCollectionData) delete dataToPost?.selectedUserId;
    const response = await methodName(endpoint, dataToPost, baseUrl);
    let appliedFilters = { ...data };
    if (!isErroneousResponse(response)) {
      const apiResponse = response.data;
      if (getCollectionData) {
        dispatch(fetchSavedCollection());
      }
      if (seperateApiData) {
        dispatch(
          savePaTopicSubTopicData(seperatePaTopicSubTopicData(apiResponse))
        );
      }
      if (apiIdentifier === API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST) {
        if (updateImpersonatorList)
          dispatch(updateImpersonatedUserList(apiResponse));
        let selectedUserId = [];
        if (isEmpty(apiResponse)) {
          selectedUserId.push('00000');
          apiResponse.push({
            employeeIdHRO: '00000',
            employeeName: 'NA',
          });
        }
        appliedFilters = {
          ...appliedFilters,
          selectedUserId: isEmptyOrNull(appliedFilters?.selectedUserId)
            ? selectedUserId
            : appliedFilters?.selectedUserId,
        };
      }
      return batch(() => {
        if (data?.isBusinessComparisonPage)
          dispatch(saveAppliedFiltersBc(appliedFilters));
        else dispatch(saveAppliedFiltersInRedux(appliedFilters));
        dispatch(
          seedDataFetchSuccess(
            formatData ? formatTreeData(apiResponse) : apiResponse,
            apiIdentifier
          )
        );
      });
    }
  } catch (error) {
    batch(() => {
      dispatch(seedDataFetchFailure(error, apiIdentifier));
    });
  } finally {
    dispatch(endAPICall(apiIdentifier));
  }
}

async function fetchGetData(dispatch, apiIdentifier, postData) {
  let endpoint;
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  switch (apiIdentifier) {
    case API_CALL_IDENTIFIERS.FETCH_DEFAULT_USER_FILTERS:
      endpoint = `${CONFIG.PROTOTYPE_API_URLS.DEFAULT_USER_FILTERS}?employeeIdHRO=${postData.employeeIdHRO}`;
      break;
    case API_CALL_IDENTIFIERS.FETCH_COLLECTION_DATA:
      endpoint = `${CONFIG.PROTOTYPE_API_URLS.SAVE_COLLECTION}`;
      break;
    default:
      endpoint = '';
  }
  try {
    const response = await fetchGet(endpoint, baseUrl);
    if (!isErroneousResponse(response)) {
      return dispatch(seedDataFetchSuccess(response, apiIdentifier));
    }
  } catch (error) {
    batch(() => {
      dispatch(seedDataFetchFailure(error, apiIdentifier));
    });
  } finally {
    dispatch(endAPICall(apiIdentifier));
  }
}

const formatTreeData = results => {
  let responseArray = [];
  results.map(location => {
    let responseFormat = {
      ...location,
      code: location.id,
      type: CONFIG.PROTOTYPE_FILTERS.LOCATION.field,
      children: location.children.map(child => ({
        ...child,
        type: CONFIG.PROTOTYPE_FILTERS.LOCATION.field,
        code: child.id,
        children: child.children.map(grandChild => ({
          ...grandChild,
          type: CONFIG.PROTOTYPE_FILTERS.LOCATION.field,
          code: grandChild.id,
        })),
      })),
    };
    responseArray.push(responseFormat);
  });
  return responseArray;
};

function savePaTopicSubTopicData(paTopicsSubtopics) {
  return async function (dispatch) {
    dispatch({
      type: SAVE_PA_TOPICS_SUBTOPICS,
      paTopicsSubtopics,
    });
  };
}

function saveAppliedFilters(appliedFiltersObj) {
  return async function (dispatch) {
    dispatch(saveAppliedFiltersInRedux(appliedFiltersObj));
  };
}

function saveAppliedFiltersInRedux(appliedFiltersObj) {
  return {
    type: SAVE_APPLIED_FILTERS,
    filters: appliedFiltersObj,
  };
}

function saveAppliedFiltersBc(appliedFiltersObj) {
  return async function (dispatch) {
    dispatch({
      type: SAVE_APPLIED_FILTERS_BC,
      filtersBc: appliedFiltersObj,
    });
  };
}

function updateIsFilterOpen(state) {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_IS_FILTER_OPEN,
      isFilterOpen: state,
    });
  };
}

function updateLastUpdatedDate(state) {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_LAST_UPDATED_DATE,
      lastUpdatedDate: state,
    });
  };
}

function updateIsMemberFilterSelected(state) {
  return async function (dispatch) {
    dispatch({
      type: IS_MEMBER_FILTER_SELECTED,
      isMemberFilterSelected: state,
    });
  };
}

function updateImpersonatedUserList(userList) {
  return async function (dispatch) {
    dispatch(
      seedDataFetchSuccess(
        userList,
        API_CALL_IDENTIFIERS.FETCH_IMPERSONATOR_USER_LIST
      )
    );
  };
}

function updateFilterState(state) {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_FILTER_STATE,
      filterState: state,
    });
  };
}

function updateFilterStateBc(state) {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_FILTER_STATE_BC,
      filterStateBc: state,
    });
  };
}

function updateSelectedDate(state) {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_SELECTED_DATE,
      selectedDateFilter: state,
    });
  };
}

function saveCollection(collectionData, isEditModeOn) {
  return async function (dispatch) {
    batch(() => {
      // batch all Seed Data Calls dispatch as ONE
      dispatch(beginAPICall(API_CALL_IDENTIFIERS.SAVE_COLLECTION_DATA));
    });
    fetchPostList(
      dispatch,
      collectionData,
      API_CALL_IDENTIFIERS.SAVE_COLLECTION_DATA,
      isEditModeOn
    );
  };
}

function fetchSavedCollection() {
  return async function (dispatch) {
    batch(() => {
      // batch all Seed Data Calls dispatch as ONE
      dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_COLLECTION_DATA));
    });
    fetchGetData(dispatch, API_CALL_IDENTIFIERS.FETCH_COLLECTION_DATA);
  };
}

function deleteCollection(collectionId) {
  return async function (dispatch) {
    dispatch(beginAPICall(API_CALL_IDENTIFIERS.DELETE_COLLECTION_DATA));
    fetchDelete(
      dispatch,
      collectionId,
      API_CALL_IDENTIFIERS.DELETE_COLLECTION_DATA
    );
  };
}

function saveAppliedCollection(collection) {
  return async function (dispatch) {
    dispatch({
      type: SAVE_APPLIED_COLLECTION,
      collection,
    });
  };
}

function saveSelectedFiltersData(selectedFiltersData) {
  return async function (dispatch) {
    dispatch({
      type: SAVE_SELECTED_DATA_FOR_FILTERS,
      selectedFiltersData,
    });
  };
}

async function fetchDelete(dispatch, Id, apiIdentifier) {
  let endpoint;
  let getCollectionData = false;
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  switch (apiIdentifier) {
    case API_CALL_IDENTIFIERS.DELETE_COLLECTION_DATA:
      endpoint = `${CONFIG.API_URLS.SAVE_COLLECTION}${Id}`;
      getCollectionData = true;
      break;
    default:
      endpoint = '';
  }
  try {
    const response = await fetchDeleteWithId(endpoint, baseUrl);
    if (!isErroneousResponse(response)) {
      if (getCollectionData) {
        dispatch(fetchSavedCollection());
      }
      return dispatch(seedDataFetchSuccess(response, apiIdentifier));
    }
  } catch (error) {
    batch(() => {
      dispatch(seedDataFetchFailure(error, apiIdentifier));
    });
  } finally {
    dispatch(endAPICall(apiIdentifier));
  }
}

export const filterActions = {
  fetchMemberList,
  fetchSeedDataFilters,
  updateIsFilterOpen,
  updateLastUpdatedDate,
  updateIsMemberFilterSelected,
  saveAppliedFilters,
  clearFiltersData,
  updateImpersonatedUserList,
  clearDefaultFilters,
  savePaTopicSubTopicData,
  fetchFiltersForBusinessComparison,
  updateFilterState,
  updateFilterStateBc,
  saveAppliedFiltersBc,
  updateSelectedDate,
  saveCollection,
  fetchSavedCollection,
  deleteCollection,
  saveAppliedCollection,
  saveSelectedFiltersData,
};
